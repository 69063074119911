<template>
  <div class="art-guide__events-group">
    <h4 class="art-guide__events-group-title">{{ group.type }}</h4>
    <div>
      <ArtGuideEventsGroupItem
        v-for="event in group.events"
        :key="event.id"
        :event="event"
        :is-detaching="detachingIds.includes(event.id)"
        @detach-event="handleDetachEvent"
        @favorite-changed="handleChangeEventFavorite"
        @notes-changed="handleChangeEventNotes"
      />
    </div>
  </div>
</template>

<script>
import ArtGuideEventsGroupItem from '@/components/artguide/ArtGuideEventsGroupItem';

export default {
  components: { ArtGuideEventsGroupItem },
  props: {
    group: {
      type: Object,
      required: true,
    },
    detachingIds: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleDetachEvent(id) {
      this.$emit('detach-event', id);
    },
    handleChangeEventFavorite(position, isFavorite) {
      this.$emit('favorite-changed', position, isFavorite);
    },
    handleChangeEventNotes(position, notes) {
      this.$emit('notes-changed', position, notes);
    },
  },
};
</script>

<style lang="scss">
.art-guide__events-group {
  padding: 95px 0 85px;

  @media (max-width: 1199px) {
    padding: 80px 0 70px;
  }
  @media (max-width: 991px) {
    padding: 60px 0 50px;
  }
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #979797;
  }

  &-title {
    margin-bottom: 70px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 6px;
    text-transform: uppercase;

    @media (max-width: 991px) {
      margin-bottom: 60px;
    }
  }
}
</style>
