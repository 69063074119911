<template>
  <section class="art-guide__events-staff-pick-item">
    <AppRouteLink :class="{ 'art-guide__events-staff-pick-item-link': routeTo }" :route-to="routeTo">
      <div class="art-guide__image" :style="`background-image:url(${media})`">
        <span class="art-guide__event-position art-guide__event-position--bottom-left">{{ event.position }}</span>
      </div>
      <p class="art-guide__events-staff-pick-item-type">{{ event.eventable.type_for_human }}</p>
      <h3 class="art-guide__events-staff-pick-item-title">{{ event.eventable.title }}</h3>
    </AppRouteLink>
  </section>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import AppRouteLink from '@/components/partials/AppRouteLink';

export default {
  name: 'ArtGuideStaffPickEvent',
  components: { AppRouteLink },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.event.eventable);
    },
    routeTo() {
      return !this.event.is_expired ? getEntityRoute(this.event.eventable) : null;
    },
  },
};
</script>
