<template>
  <div class="current-art-guide-wrp">
    <div v-if="loader" class="app-loader-wrapper">
      <AppLoader />
    </div>
    <div v-if="!loader && !artGuide" class="container">
      <MsgEntityNotFound name="Art Guide" />
    </div>

    <div
      v-else-if="artGuide"
      class="page-wrap art-guide"
      :class="{ 'art-guide--view-list-new': viewListMode, 'art-guide--view-map': !viewListMode }"
    >
      <div class="art-guide__main-container container">
        <BackHeader
          :description="artGuide.description"
          :is-static="false"
          skip-destination-query
          :title="artGuide.title"
          with-filter
        />

        <div class="art-guide__header-buttons art-guide__header-buttons--mobile">
          <button class="art-guide__pdf-button" @click="exportArtGuidePdf">
            <TheDownloadIcon height="24" />
          </button>
          <button class="art-guide__pdf-button" @click="toggleViewMode">
            <TheMapIcon v-if="viewListMode" height="24" />
            <TheListItemsIcon v-else height="24" />
          </button>
        </div>

        <header ref="artGuideMainHeader" class="art-guide__main-header">
          <h1 class="art-guide__main-header-title">{{ artGuide.title }}</h1>
          <p class="art-guide__main-header-description">{{ artGuide.description }}</p>
        </header>

        <main v-if="!isFilterDirty && !artGuideFetching && !artGuideEvents.length">
          <MsgEntityCollectionEmpty message="Nothing has been added to this Art Guide yet." />
        </main>

        <template v-else>
          <div v-if="!viewListMode" ref="artGuideHeaderContainer" class="art-guide__header-container">
            <div class="art-guide__header-buttons">
              <div>
                <button class="art-guide__pdf-button p-0" @click="exportArtGuidePdf">
                  <TheDownloadIcon height="24" />
                  <span class="art-guide__pdf-button-label">Download this Guide</span>
                </button>
              </div>
              <div class="art-guide__header-buttons-second-part">
                <button class="art-guide__pdf-button" @click="toggleViewMode">
                  <TheListItemsIcon height="22" />
                </button>
                <AppDataFilterOpenButton :appearing-with-animation="false" @click="handleOpenFilter" />
              </div>
            </div>
          </div>

          <main class="art-guide__main-content-block" :style="{ height: mainContentHeight }">
            <div ref="mapContent" class="art-guide__map-content">
              <aside class="art-guide__map">
                <div
                  v-show="artGuideEvents.length"
                  class="art-guide__map-item"
                  :style="{ left: `${mapLeftPosition}px` }"
                >
                  <MglMap :access-token="mapAccessToken" :map-style="mapStyle" @load="onMapLoaded">
                    <MglMarker
                      v-for="marker in mapPoints"
                      :key="`map-marker_${marker.key}`"
                      :coordinates="[marker.lng, marker.lat]"
                      :offset="[0, -14]"
                      :style="[!viewListMode && mainContentHeight !== 'auto' ? { height: mainContentHeight } : {}]"
                    >
                      <ArtGuideMapMarkerIcon slot="marker" :position="marker.position" />
                    </MglMarker>
                  </MglMap>
                  <button
                    v-show="isMapLoaded"
                    ref="viewLauncher"
                    class="art-guide__launch-view-btn"
                    @click="toggleViewMode"
                  >
                    <span v-if="!viewListMode">Launch list view</span>
                    <span v-else>Launch map</span>
                  </button>
                </div>
              </aside>

              <div class="art-guide__sidebar">
                <div v-if="!viewListMode" class="art-guide__events-groups-small">
                  <div v-for="group in eventsByType" :key="group.type" class="art-guide__events-groups-small-item">
                    <h2 class="art-guide__events-groups-small-item-header">{{ group.type }}</h2>
                    <ArtGuideEvent v-for="event in group.events" :key="event.id" :event="event" />
                  </div>
                </div>

                <div v-else>
                  <div class="art-guide__pdf-button-head-container">
                    <button class="art-guide__pdf-button p-0" @click="exportArtGuidePdf">
                      <TheDownloadIcon height="24" />
                      <span class="art-guide__pdf-button-label">Download this Guide</span>
                    </button>
                  </div>
                  <div class="art-guide__head-info">
                    <AppSectionWithViewToggling v-if="artGuide.message_from_team" title="Message from the team">
                      <template #body>{{ artGuide.message_from_team }}</template>
                    </AppSectionWithViewToggling>

                    <AppSectionWithViewToggling v-if="artGuide.emergency_contact" title="Emergency contact">
                      <template #body>
                        <div class="art-guide__emergency-contact-info">
                          <p>{{ artGuide.emergency_contact.name }}</p>
                          <p v-show="artGuide.emergency_contact.phone">
                            <a :href="`tel:${artGuide.emergency_contact.phone_formatted}`">{{
                              artGuide.emergency_contact.phone
                            }}</a>
                          </p>
                          <p v-show="artGuide.emergency_contact.email">
                            <a :href="`mailto:${artGuide.emergency_contact.email}`">{{
                              artGuide.emergency_contact.email
                            }}</a>
                          </p>
                        </div>
                      </template>
                    </AppSectionWithViewToggling>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="viewListMode">
              <div v-if="staffPickEvents.length" class="art-guide__events-staff-pick-container">
                <h4 class="art-guide__events-staff-pick-header">Staff picks</h4>
                <div class="art-guide__events-staff-pick">
                  <ArtGuideStaffPickEvent
                    v-for="event in staffPickEvents"
                    :key="`artGuide-staffPickEvent-${event.id}`"
                    :event="event"
                  />
                </div>
              </div>
              <div class="art-guide__events-group-container">
                <div>
                  <ArtGuideEventsGroup
                    v-for="group in eventsByType"
                    :key="group.type"
                    :group="group"
                    :detaching-ids="detachingEventIds"
                    @detach-event="deleteEntityFromArtGuide"
                    @favorite-changed="handleChangeEventFavorite"
                    @notes-changed="handleChangeEventNotes"
                  />
                </div>
                <div class="art-guide__filter-button-container-absolute">
                  <AppDataFilterOpenButton :appearing-with-animation="false" @click="handleOpenFilter" />
                </div>
              </div>
            </template>
          </main>
        </template>
      </div>

      <div v-show="viewListMode && artGuideEvents.length" class="art-guide__map-full-width">
        <MglMap :access-token="mapAccessToken" :map-style="mapStyle" @load="onFullWidthMapLoaded">
          <MglMarker
            v-for="marker in mapPoints"
            :key="`map-full-marker_${marker.key}`"
            :coordinates="[marker.lng, marker.lat]"
            :offset="[0, -14]"
          >
            <ArtGuideMapMarkerIcon slot="marker" :position="marker.position" />
          </MglMarker>
        </MglMap>
        <button ref="viewLauncherFullWidth" class="art-guide__launch-view-btn" @click="toggleViewMode">
          <span v-if="!viewListMode">Launch list view</span>
          <span v-else>Launch map</span>
        </button>
      </div>
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { MglMap, MglMarker } from 'vue-mapbox';
import { exportArtGuideToPDF } from '@/api/exportToFile';
import { EntityTypes } from '@/helpers/entityType';
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';
import { getMapBounds, MAP_ACCESS_TOKEN, MAP_STYLE_URL } from '@/helpers/mapboxHelper';
import { validateAndGetCoordinates } from '@/helpers/GeolocationHelper';
import ArtGuideDataFilterHelper from '@/helpers/data-filter/ArtGuideDataFilterHelper';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import artGuideQuery from '@/graphql/artGuide/ArtGuide.single.query.gql';
import artGuideEntityTypesQuery from '@/graphql/artGuide/ArtGuideEntityTypes.query.gql';
import deleteEntityFromArtGuideMutation from '@/graphql/artGuide/DeleteEntityFromArtGuide.mutation.gql';

import BackHeader from '@/components/partials/BackHeader';
import ArtGuideEvent from '@/components/artguide/ArtGuideEvent';
import AppSectionWithViewToggling from '@/components/partials/elements/AppSectionWithViewToggling';
import ArtGuideEventsGroup from '@/components/artguide/ArtGuideEventsGroup';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import ArtGuideStaffPickEvent from '@/components/artguide/ArtGuideStaffPickEvent';
import ArtGuideMapMarkerIcon from '@/components/artguide/ArtGuideMapMarkerIcon';
import TheDownloadIcon from '@/components/icons/TheDownloadIcon.vue';
import TheMapIcon from '@/components/icons/TheMapIcon.vue';
import TheListItemsIcon from '@/components/icons/TheListItemsIcon.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const MAP_PADDING_SIZE = 80;

const ART_GUIDE_EVENTS_TYPES = [
  EntityTypes.event,
  EntityTypes.exhibition,
  EntityTypes.art_space,
  EntityTypes.art_fair,
  EntityTypes.mini_cultivist,
  EntityTypes.historical_site,
  EntityTypes.insider_tip_element,
  EntityTypes.story,
];
const MAP_LEFT_POS_BY_WINDOW_WIDTH = [
  { width: 991, left: 0 },
  { width: 1000, left: -36 },
  { width: 1050, left: -60 },
  { width: 1100, left: -86 },
  { width: 1150, left: -110 },
  { width: 1199, left: -136 },
  { width: 1200, left: -46 },
  { width: 1250, left: -71 },
  { width: 1300, left: -96 },
  { width: 1350, left: -120 },
  { width: 1400, left: -126 },
  { width: 1450, left: -151 },
  { width: 1500, left: -176 },
  { width: 1550, left: -201 },
  { width: 1600, left: -226 },
  { width: 1650, left: -251 },
  { width: 1700, left: -276 },
  { width: 1750, left: -301 },
  { width: 1800, left: -326 },
  { width: 1850, left: -351 },
  { width: 1900, left: -376 },
  { width: 1950, left: -401 },
  { width: 2000, left: -426 },
];

export default {
  name: 'MyArtGuideSinglePage',
  components: {
    MsgEntityCollectionEmpty,
    MsgEntityNotFound,
    TheListItemsIcon,
    TheMapIcon,
    TheDownloadIcon,
    ArtGuideMapMarkerIcon,
    ArtGuideStaffPickEvent,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    ArtGuideEventsGroup,
    AppSectionWithViewToggling,
    ArtGuideEvent,
    BackHeader,
    MglMap,
    MglMarker,
  },
  metaInfo() {
    return {
      title: this.artGuide?.title || 'Art Guide',
    };
  },

  data() {
    return {
      artGuide: null,
      artGuideEvents: [],
      artGuideFetching: true,

      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE_URL,
      isMapLoaded: false,

      viewListMode: true,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mainContentHeight: 'auto',

      filterGroups: [],
      filterValues: {
        types: [],
      },
      isFilterValuesChanged: false,
      detachingEventIds: [],
    };
  },

  computed: {
    eventsByType() {
      const eventsTmp = {};
      const eventsByType = [];

      this.artGuideEvents.forEach((event) => {
        const groupType = event.eventable.__typename;

        if (!eventsTmp[groupType]) {
          eventsTmp[groupType] = {
            type: event.group,
            events: [],
          };
        }
        eventsTmp[groupType].events.push(event);
      });

      ART_GUIDE_EVENTS_TYPES.forEach((group) => {
        if (eventsTmp[group]) {
          eventsByType.push({ ...eventsTmp[group] });
        }
      });

      return eventsByType;
    },
    filterQueryVariables() {
      return ArtGuideDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isFilterDirty() {
      return ArtGuideDataFilterHelper.isDirty(this.filterValues);
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    loader() {
      return !!this.$store.state.loader;
    },
    mapLeftPosition() {
      if (this.viewListMode) {
        return 0;
      }

      for (let item of MAP_LEFT_POS_BY_WINDOW_WIDTH) {
        if (this.windowWidth <= item.width) {
          return item.left;
        }
      }

      return MAP_LEFT_POS_BY_WINDOW_WIDTH[MAP_LEFT_POS_BY_WINDOW_WIDTH.length - 1].left;
    },

    mapPoints() {
      return this.artGuideEvents
        .map((event) => {
          const coordinates = validateAndGetCoordinates(event.eventable);
          if (coordinates === false) {
            return;
          }

          return {
            id: event.id,
            __typename: event.__typename,
            key: `${event.__typename}_${event.id}`,
            title: event.title,
            lat: coordinates.latitude,
            lng: coordinates.longitude,
            position: event.position,
            isExpired: event.is_expired,
          };
        })
        .filter((m) => m);
    },

    staffPickEvents() {
      const events = [];

      if (!this.eventsByType) {
        return events;
      }
      this.eventsByType.forEach((group) => {
        group.events.forEach((event) => {
          if (!event.eventable.staff_pick) {
            return;
          }
          events.push(event);
        });
      });
      return events;
    },
  },

  watch: {
    $route() {
      this.fetchArtGuide();
    },
  },

  created() {
    this.$store.dispatch('setLoader', true);
    this.fetchArtGuide();
    this.getFilterData();

    this._debounceResize = handleResizeWithDebounce();
    this.$root.$on('openPDF', this.exportArtGuidePdf);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    this.$root.$off('openPDF', this.exportArtGuidePdf);
    window.removeEventListener('resize', this.handleResize);

    this._map = null;
    this._mapFullWidth = null;
    this._debounceResize = null;
  },

  methods: {
    fetchArtGuide(onlyEvents = false) {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.artGuideFetching = true;
      this.$apollo
        .query({
          query: artGuideQuery,
          fetchPolicy: 'no-cache',
          variables: {
            ...variables,
            ...this.filterQueryVariables,
            onlyEvents,
          },
        })
        .then(({ data }) => {
          const artGuide = data?.artGuide || null;

          if (variables.onlyId && artGuide?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, artGuide.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          if (artGuide) {
            if (onlyEvents) {
              this.artGuide.events = artGuide.events;
            } else {
              this.artGuide = artGuide;
            }
            this.artGuideEvents = artGuide.events;
          }

          this.artGuideFetching = false;
          this.$store.dispatch('setLoader', false);
        });
    },
    getFilterData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);
      if (!variables.slug) {
        return;
      }

      this.$apollo
        .query({
          query: artGuideEntityTypesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            slug: variables.slug,
          },
        })
        .then(({ data = { artGuideEntityTypes: [] } }) => {
          this.filterGroups = ArtGuideDataFilterHelper.prepareFilterGroups({ types: data.artGuideEntityTypes });
        });
    },

    onMapLoaded({ map }) {
      this.isMapLoaded = true;

      if (!this._map) {
        this._map = map;
      }

      const padding = this.mapPoints.length ? MAP_PADDING_SIZE : 0;
      this._map.fitBounds(getMapBounds(this.mapPoints), { padding });
    },
    onFullWidthMapLoaded({ map }) {
      if (!this._mapFullWidth) {
        this._mapFullWidth = map;
      }

      let padding = 0;

      if (this.mapPoints.length) {
        padding = this.isMobileScreen || window.innerHeight < 600 ? MAP_PADDING_SIZE : 200;
      }
      this._mapFullWidth.fitBounds(getMapBounds(this.mapPoints), { padding });
    },

    handleResize() {
      this._debounceResize(this.rerenderAfterResizing);
    },
    rerenderAfterResizing() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      this.$nextTick().then(() => {
        this.changeMainContentHeight();

        this.$nextTick().then(() => {
          const coords = getMapBounds(this.mapPoints);
          this.resizeMap(this._map, coords);

          if (this.viewListMode) {
            const fullMapPadding = this.isMobileScreen || window.innerHeight < 600 ? MAP_PADDING_SIZE : 200;
            this.resizeMap(this._mapFullWidth, coords, fullMapPadding);
          }
        });
      });
    },
    toggleViewMode() {
      this.viewListMode = !this.viewListMode;

      const viewLauncher = this.$refs.viewLauncher;
      if (viewLauncher instanceof HTMLButtonElement) {
        viewLauncher.blur();
      }
      const viewLauncherFullWidth = this.$refs.viewLauncherFullWidth;
      if (viewLauncherFullWidth instanceof HTMLButtonElement) {
        viewLauncherFullWidth.blur();
      }

      this.$nextTick().then(() => {
        this.changeMainContentHeight();
        this.$nextTick().then(() => {
          const coords = getMapBounds(this.mapPoints);
          this.resizeMap(this._map, coords);

          if (this.viewListMode) {
            const fullMapPadding = this.isMobileScreen || window.innerHeight < 600 ? MAP_PADDING_SIZE : 200;
            this.resizeMap(this._mapFullWidth, coords, fullMapPadding);
          }
        });
      });
    },
    resizeMap(map, coords, padding) {
      if (!map || !coords.length) {
        return;
      }
      map.resize();
      map.fitBounds(coords, { padding: padding || MAP_PADDING_SIZE });
    },
    changeMainContentHeight() {
      if (this.viewListMode || this.windowWidth < 992) {
        this.mainContentHeight = 'auto';
        return;
      }

      let artGuideHeaderHeight = 205; // main-header + header-container height values
      const artGuideWrapperPaddingTop = 100;
      let styles;

      const mainHeaderElem = this.$refs.artGuideMainHeader;
      const headerContainer = this.$refs.artGuideHeaderContainer;

      if (mainHeaderElem instanceof HTMLElement) {
        styles = window.getComputedStyle(mainHeaderElem);
        artGuideHeaderHeight = mainHeaderElem.offsetHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom);
      }
      if (headerContainer instanceof HTMLElement) {
        styles = window.getComputedStyle(headerContainer);
        artGuideHeaderHeight +=
          headerContainer.offsetHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom);
      }

      this.mainContentHeight = this.windowHeight - artGuideHeaderHeight - artGuideWrapperPaddingTop + 'px';
    },

    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);

      if (this.isFilterValuesChanged) {
        this._map.fitBounds(getMapBounds(this.mapPoints), { padding: MAP_PADDING_SIZE });
      }
      this.isFilterValuesChanged = false;
    },
    handleChangeFilterValues() {
      this.isFilterValuesChanged = true;
      this.fetchArtGuide(true);
    },

    handleChangeEventFavorite(position, isFavorite) {
      const event = this.artGuideEvents.find((event) => event.position === position);
      if (event) {
        event.is_favorite_by_current_user = isFavorite;
      }
    },
    handleChangeEventNotes(position, notes) {
      const event = this.artGuideEvents.find((event) => event.position === position);
      if (event) {
        event.notes = notes;
      }
    },
    deleteEntityFromArtGuide(id) {
      if (this.detachingEventIds.includes(id)) {
        return;
      }
      this.detachingEventIds.push(id);

      this.$apollo
        .mutate({
          mutation: deleteEntityFromArtGuideMutation,
          variables: {
            id,
          },
        })
        .then(({ data }) => {
          const events = data?.deleteEntityFromArtGuide || [];
          if (!events.length) {
            this.detachingEventIds.splice(
              this.detachingEventIds.findIndex((eId) => eId === id),
              1
            );
            return;
          }

          this.artGuideEvents = this.artGuideEvents
            .filter((event) => event.id !== id)
            .map((event) => {
              const eventWithPos = events.find((e) => e.id === event.id);
              return { ...event, position: eventWithPos ? eventWithPos.position : event.position };
            });
        })
        .catch(() => {
          this.detachingEventIds.splice(
            this.detachingEventIds.findIndex((eId) => eId === id),
            1
          );
        });
    },

    exportArtGuidePdf() {
      this.$store.dispatch('setLoader', true);

      exportArtGuideToPDF({
        id: this.artGuide.id,
        title: this.artGuide.title,
      })
        .catch(() => {
          this.$toast.error(
            'Unfortunately, you could not download the Art Guide. Please contact with the administrator.'
          );
        })
        .finally(() => {
          this.$store.dispatch('setLoader', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-art-guide-wrp {
  padding-top: 60px;
  height: 100vh;

  @media (min-width: 769px) {
    padding-top: 100px;
  }
}

.blank_page {
  padding-top: 50px;
}
</style>
