<template>
  <div class="art-guide__item" :class="{ 'art-guide__item--expired': event.is_expired }">
    <AppRouteLink :class="{ 'art-guide__caption-link': routeTo }" :route-to="routeTo">
      <div class="art-guide__image" :style="`background-image: url(${media})`">
        <span class="art-guide__event-position">{{ event.position }}</span>
        <EntityCardLabel v-if="!event.is_expired && entityCardLabel" :label="entityCardLabel" />
      </div>

      <h3 class="art-guide__caption">{{ event.eventable.title }}</h3>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityCardLabel } from '@/helpers/entityCardLabel';
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import AppRouteLink from '@/components/partials/AppRouteLink';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';

export default {
  name: 'ArtGuideEvent',
  components: { AppRouteLink, EntityCardLabel },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    entityCardLabel() {
      return getEntityCardLabel(this.event.eventable);
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.event.eventable);
    },
    routeTo() {
      return !this.event.is_expired ? getEntityRoute(this.event.eventable) : null;
    },
  },
};
</script>
