import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_TYPES } from '@/helpers/data-filter/DataFilterTypes';

class ArtGuideDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_TYPES]: [],
  };
  validValuesTypes = {
    array: [FILTER_TYPE_TYPES],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.eventableTypes
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems[FILTER_TYPE_TYPES],
        itemsType: FILTER_TYPE_TYPES,
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        eventableTypes: transformed[FILTER_TYPE_TYPES],
      },
    };
  }

  isDirty(values = {}) {
    return values && values[FILTER_TYPE_TYPES].length;
  }
}

export default ArtGuideDataFilterHelper.create();
