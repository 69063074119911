<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.84 23.36">
    <g>
      <polygon
        class="cls-1"
        points="1.06 2.62 9.51 1.06 18.97 2.62 26.78 1.06 26.78 20.78 19.02 22.3 9.51 20.23 1.06 22.3 1.06 2.62"
      />
      <line class="cls-1" x1="19.02" y1="3.13" x2="19.02" y2="22.3" />
      <line class="cls-1" x1="9.51" y1="1.58" x2="9.51" y2="20.23" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheMapIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #363636;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}
</style>
