<template>
  <section :class="['app-section-view-toggle', { 'app-section-view-toggle--opened': isOpen }]">
    <header class="app-section-view-toggle__header" @click="toggleView">
      <h4 class="app-section-view-toggle__title">{{ title }}</h4>
    </header>
    <CollapseTransition :duration="200">
      <div
        v-show="isOpen"
        :class="['app-section-view-toggle__body', { 'app-section-view-toggle__body--px-0': bodyWithoutHorizPadding }]"
      >
        <slot name="body" />
      </div>
    </CollapseTransition>
  </section>
</template>

<script>
import CollapseTransition from '@/components/transitions/CollapseTransition';

export default {
  components: { CollapseTransition },
  props: {
    bodyWithoutHorizPadding: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleView() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.app-section-view-toggle {
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #a1a1a1;
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '+';
      margin-right: 10px;
      font-weight: 500;
    }
  }

  &--opened &__header {
    &::before {
      content: '–';
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__body {
    padding: 20px 5px 10px 22px;

    &--px-0 {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      margin-bottom: 0;
    }
    a {
      color: #363636;
    }
  }
}
</style>
