<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 155.5">
    <g>
      <line class="cls-1" x1="89.5" y1="150" x2="211.5" y2="150" />
      <line class="cls-1" x1="89.5" y1="116" x2="211.5" y2="116" />
      <line class="cls-1" x1="89.5" y1="78" x2="211.5" y2="78" />
      <line class="cls-1" x1="89.5" y1="43" x2="211.5" y2="43" />
      <line class="cls-1" x1="89.5" y1="6" x2="211.5" y2="6" />
      <rect class="cls-2" x="5" y="4.5" width="59" height="49" />
      <rect class="cls-2" x="4.5" y="102" width="59" height="49" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheListItemsIcon',
};
</script>

<style scoped>
.cls-1,
.cls-2 {
  fill: none;
  stroke: #363636;
  stroke-width: 9px;
}
.cls-1 {
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.cls-2 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
