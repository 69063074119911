<template>
  <div class="art-guide__marker">
    <span>{{ position }}</span>
  </div>
</template>

<script>
export default {
  name: 'ArtGuideMapMarkerIcon',
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
};
</script>
