<template>
  <div v-if="event.eventable.accessType" class="art-guide__access-type">
    <template v-if="accessTypeChecker.accessByTickets">
      <TicketRequestAction
        v-if="isArtSpace"
        :event="event.eventable"
        object="exhibition"
        select-from-exhibitions
        label-class="art-guide__access-type-name"
        wrapper-class="art-guide__access-type-wrapper"
      />
      <TicketRequestAction
        v-else-if="isExhibition"
        :event="event.eventable"
        object="exhibition"
        :object-id="event.eventable.id"
        label-class="art-guide__access-type-name"
        wrapper-class="art-guide__access-type-wrapper"
      />
      <TicketRequestAction
        v-else-if="isHistoricalSite"
        :event="event.eventable"
        object="historical_site"
        label-class="art-guide__access-type-name"
        wrapper-class="art-guide__access-type-wrapper"
      />
    </template>

    <UseCultivistCardAction
      v-else-if="accessTypeChecker.accessByCard"
      :event="event.eventable"
      label-class="art-guide__access-type-name"
      wrapper-class="art-guide__access-type-wrapper"
    />

    <div v-else-if="accessTypeChecker.accessFree" class="art-guide__access-type-wrapper cur-default">
      <span>
        <TheFreeSignIcon />
      </span>
      <span class="art-guide__access-type-name">{{ accessType.name }}</span>
    </div>
  </div>
</template>

<script>
import { EntityTypes } from '@/helpers/entityType';
import { checkAccessType } from '@/helpers/accessType';

import TheFreeSignIcon from '@/components/icons/TheFreeSignIcon.vue';
import TicketRequestAction from '@/components/partials/actions/TicketRequestAction';
import UseCultivistCardAction from '@/components/partials/actions/UseCultivistCardAction';

export default {
  name: 'ArtGuideEventAccessType',
  components: { TheFreeSignIcon, TicketRequestAction, UseCultivistCardAction },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    accessType() {
      return this.event.eventable.accessType;
    },
    accessTypeChecker() {
      return checkAccessType(this.accessType?.id);
    },
    isArtSpace() {
      return this.event.eventable.__typename === EntityTypes.art_space;
    },
    isExhibition() {
      return this.event.eventable.__typename === EntityTypes.exhibition;
    },
    isHistoricalSite() {
      return this.event.eventable.__typename === EntityTypes.historical_site;
    },
  },
};
</script>
